<template>
  <div type="flex" justify="center">
    <!-- <otp :digit-count="6" @update:otp="handleOnComplete($event)"></otp> -->
    <div class="container" id="otpContainer">
      <v-otp-input
        ref="otpValue"
        input-classes="otp-input"
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        separator="-"
        :conditionalClass="['one', 'two', 'three', 'four']"
        :isDisabled="disableInput"
        @on-complete="handleOtpChange"
        @on-change="handleOtpChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update-otp-value'],
  props: {
    disableInput: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleOtpChange(enteredOtp) {
      this.$emit('update-otp-value', enteredOtp);
    },
  },
};
</script>

<style>
#otpContainer {
  padding: 10px 40px;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 10px 10px;
  margin: 0px 10px 10px 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#otpContainer input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
